import jsSHA from "jssha";

export function getEmailHash(email: string) {
  const shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" })

  shaObj.update(email);

  const hash = shaObj.getHash("HEX")

  return hash
}
