import React from 'react'
import { makeStyles } from '@mui/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import PaymentForm from './PaymentForm'
import Review from './Review'
import AddressForm from './AddressForm'
import DoOrder from './DoOrder'
import { getCartHash } from '../../lib/cart_hook'
import OneActionDialog from '../../components/generic/OneActionDialog'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import { useTranslation } from 'react-i18next'
import {env} from '../../../environment'
import TechnicalErrorDialog from '../../components/generic/TechnicalErrorDialog'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1140 + theme.spacing(2) * 2)]: {
      width: 1140,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

const steps: string[] = []

if (env.getLanguage() == 'de') {
  steps.push('Anschrift')
  steps.push('Zahlung')
  steps.push('Auftragübersicht')
} else {
  steps.push('Address')
  steps.push('Payment')
  steps.push('Order Overview')
}

function getStepContent(stepNumber: number, props: any) {
  switch (stepNumber) {
    case 0:
      return (
        <AddressForm
          setErrorOpen={props.setErrorOpen}
          setValid={props.setValid}
        />
      )
    case 1:
      return (
        <PaymentForm
          setErrorOpen={props.setErrorOpen}
          setValid={props.setValid}
        />
      )
    case 2:
      return (
        <Review
          setErrorOpen={props.setErrorOpen}
          setNewsletter={props.setNewsletter}
          setValid={props.setValid}
        />
      )
    case 3:
      return (
        <DoOrder
          newsletter={props.newsletter}
          setErrorOpen={props.setErrorOpen}
        />
      )
    default:
      throw new Error('Unknown step')
  }
}

export default function Checkout() {
  const trackingName = 'checkout'
  const { t } = useTranslation('translation')

  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [showNavigation, setShowNavigation] = React.useState(true)

  const [valid, setValid] = React.useState(false)
  const [newsletter, setNewsletter] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [cartHash, setCartHash] = React.useState(getCartHash())
  const [errorOpen, setErrorOpen] = React.useState(false)

  const handleNext = () => {
    window.scrollTo(0, 0)

    trackEvent({
      category: trackingName,
      action: 'button',
      label: 'next_step_from_' + activeStep,
    })

    if (activeStep == 1) {
      // Refresh carthash after setting payment
      setCartHash(getCartHash())
    }

    if (activeStep == 2) {
      if (getCartHash() != cartHash) {
        setOpenDialog(true)
      } else {
        setActiveStep(activeStep + 1)
        setShowNavigation(false)
      }
    } else {
      setActiveStep(activeStep + 1)
      setShowNavigation(true)
    }
  }

  const handleBack = () => {
    window.scrollTo(0, 0)

    trackEvent({
      category: trackingName,
      action: 'button',
      label: 'back_step_from_' + activeStep,
    })

    setShowNavigation(true)
    setActiveStep(activeStep - 1)
  }

  const title = t('Warenkorb verändert')
  const dialog = t(
    'In der Zwischenzeit hat sich dein Warenkorb verändert. Bitte prüfe noch einmal die Produkte!',
  )

  const handleGoToCart = () => {
    window.scrollTo(0, 0)

    trackEvent({
      category: trackingName,
      action: 'button',
      label: 'back_to_cart',
    })

    setOpenDialog(false)
    setCartHash(getCartHash())
  }

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Typography align="center" component="h1" variant="h4">
            {t('Bestellung')}
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            <React.Fragment>
              {getStepContent(activeStep, {
                setValid: setValid,
                setNewsletter: setNewsletter,
                setErrorOpen: setErrorOpen,
                newsletter: newsletter,
              })}

              {showNavigation == true && (
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button className={classes.button} onClick={handleBack}>
                      {t('Zurück')}
                    </Button>
                  )}
                  <Button
                    className={classes.button}
                    color="primary"
                    data-test="checkout_next"
                    disabled={!valid || errorOpen}
                    onClick={handleNext}
                    variant="contained"
                  >
                    {activeStep === steps.length - 1
                      ? t('Jetzt kaufen')
                      : t('Weiter')}
                  </Button>
                </div>
              )}
            </React.Fragment>
          </React.Fragment>
          <TechnicalErrorDialog open={errorOpen} setOpen={setErrorOpen} />
        </main>
      </React.Fragment>

      <OneActionDialog
        dialog={dialog}
        open={openDialog}
        positive={t('OK, nochmal zum Warenkorb')}
        positiveAction={handleGoToCart}
        setOpen={setOpenDialog}
        title={title}
      />
    </>
  )
}
