import {env} from '../../environment'
import { serializeToForm } from '../utils/utils'
import fetchError from './global/error_api'

export const order = (orderData: any) => {
  const form = serializeToForm(orderData)

  let url = ''

  if (orderData.payment == 'testok') {
    url = env.getServiceHost() + 'order/orderDummyOk'
  } else if (orderData.payment == 'testfail') {
    url = env.getServiceHost() + 'order/orderDummyFail'
  } else {
    url = env.getServiceHost() + 'order/create'
  }

  return fetch(url, { method: 'post', body: form })
    .then(response => response.text())
    .then(response => {
      try {
        return JSON.parse(response)
      } catch (err) {
        fetchError({
          data: '[FATAL] Kaputte Order-Response.',
        })

        fetchError({
          data: '[INFO] Kaputte Order-Response: ' + response,
        })

        return {
          error: 1,
          data:
            'Puuuh...die Auftragsschnittstelle hat gerade einen merkwürdigen Fehler zurückgeliefert. Scheinbar gibt es technische Schwierigkeiten. Es kann sein, dass dein Auftrag eingegangen ist, es kann aber auch sein, dass es nicht geklappt hat. Bitte sende eine Mail an service@artboxone.de mit der Rückfrage zum Bestelleingang. Bitte nicht versuchen den Auftrag mehrfach abzusenden!',
        }
      }
    })
}
