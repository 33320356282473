import { order } from './order_api'
import { useState, useEffect } from 'react'
import { getSeenPrices, getCartPaymentData } from './cart_hook'
import { CartItemCalculated } from '../types'
import { getPriceCountry, getCartCountry , getCurrencyIso } from '../utils/CountrySelection'
import { getChannel } from './aref'
import {env} from '../../environment'
import { getHash } from '../utils/Crypto'

export interface useOrderDataProps {
  cart: any
}

export const mapOrderMaterial = (material: string) => {
  let result = 'poster'

  if (material == 'cases') {
    result = 'case'
  } else if (material === 'cups') {
    result = 'cups'
  } else if (
    material === 'tshirt' ||
    material == 'hoodie' ||
    material == 'sweatshirt'
  ) {
    result = 'fashion'
  } else if (
    material === 'wallcalendar' ||
    material === 'foldingcalendar' ||
    material === 'deskcalendar' ||
    material === 'familyplaner' ||
    material === 'squarecalendar'
  ) {
    result = 'cal'
  }

  return result
}

export const useOrderData = (props: useOrderDataProps) => {
  const [orderData, setOrderData] = useState(undefined)
  const cart = props.cart.cart

  const cartItems: any = []
  const priceCountry = getPriceCountry(getCartCountry())

  props.cart.cartItems.map((cartItem: CartItemCalculated) => {
    const price = cartItem.productMaterial?.price[priceCountry]
    let customizedText = ''

    if (cartItem.customText != '' && cartItem.customText != undefined) {
      customizedText = cartItem.customText
    }

    cartItems.push({
      item: {
        id: cartItem.motiveId,
        name: cartItem.productMotive?.name,
        pixumImageId: cartItem.productMotive?.pixumImageId,
        type: mapOrderMaterial(cartItem.material),
      },
      material: {
        iid: cartItem.materialIId,
        price: price,
        materialName: cartItem.material,
        materialVariant: cartItem.productMaterial?.name,
      },
      materialIId: cartItem.materialIId,
      count: cartItem.count,
      customizedText: customizedText,
      preview: cartItem.previewUrl,
    })
  })

  const deliveryAddress = {
    country: {
      iso: cart.delivery_address.country.toUpperCase(),
    },
    firstname: cart.delivery_address.first_name,
    lastname: cart.delivery_address.last_name,
    street: cart.delivery_address.street,
    housenumber: cart.delivery_address.housenumber,
    zip: cart.delivery_address.zip,
    city: cart.delivery_address.city,
    email: cart.mail_address,
    company:
      cart.delivery_address.company !== undefined
        ? cart.delivery_address.company
        : '',
  }

  let invoiceAddress: any = {}

  if (typeof cart.invoice_address !== 'undefined') {
    invoiceAddress = {
      country: {
        iso: cart.invoice_address.country,
      },
      firstname: cart.invoice_address.first_name,
      lastname: cart.invoice_address.last_name,
      street: cart.invoice_address.street,
      housenumber: cart.invoice_address.housenumber,
      zip: cart.invoice_address.zip,
      city: cart.invoice_address.city,
      company:
        cart.invoice_address.company !== undefined
          ? cart.invoice_address.company
          : '',
    }
  } else {
    invoiceAddress = { firstname: '', lastname: '' }
  }

  let paymentName = ''
  let paymentNumber = ''

  if (cart.payment == 'sepa') {
    const paymentData = getCartPaymentData()
    paymentName = paymentData.paymentName
    paymentNumber = paymentData.paymentNumber
  }

  const seenPrices = getSeenPrices()

  const od: any = {
    payment: cart.payment,
    newsletter: props.cart.newsletter ? '1' : '0',
    paymentOwner: paymentName,
    paymentNumber: paymentNumber,
    priceSum: props.cart.sumGross,
    priceBonus: props.cart.sumBonus,
    channel: getChannel(),
    currency: getCurrencyIso(cart.delivery_address.country),
    paymentSuccess: env.getBaseUrl() + '/orderpush/success/',
    paymentFailure: env.getBaseUrl() + '/orderpush/failure/',
    deliveryAddress: deliveryAddress,
    invoiceAddress: invoiceAddress,
    cartItems: cartItems,
    run: props.cart.run,
    version: 2,
    seenPrices: seenPrices,
    uuid: typeof cart.uuid === 'string' ? cart.uuid : '',
  }

  const orderHash = getHash(od)

  od['paymentSuccess'] += orderHash
  od['paymentFailure'] += orderHash

  if (
    cart.voucher_code != undefined &&
    cart.voucher_code != '' &&
    seenPrices.bonus > 0
  ) {
    od['voucher'] = cart.voucher_code
  }

  const signature = getHash(od)

  useEffect(() => {
    setOrderData(undefined)
    order(od).then((response) => {
      setOrderData(response)
    })
  }, [signature])

  return { result: orderData, orderData: od, orderHash: orderHash }
}
